
.sideMenuContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .sideMenuItemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 0; 
    width: 100%;
    text-align: center;
  }

  .sideMenuItemContainer.selected{
    border: 2px solid white;
  }
  
  .itemImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .itemImages {
    width: 80%;
    cursor: pointer;
  }